import React, { useState } from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { DOCS_ADDRESS, REPO_ADDRESS } from '../data/links';
import { WEBSITE_LABEL } from '../data/global';
import Logo from './Logo';

export default function Header() {
  const [isActive, setActive] = useState(false);

  const menuClassName = classnames('navbar-menu', {
    'is-active': isActive
  });

  return (
    <nav className="navbar" role="navigation" aria-label="dropdown navigation">
      <div className="navbar-brand">
        <Link to={`/`} className="navbar-item">
          <figure className="image is-32x32">
            <Logo fill="#fff" width={32} height={32} />
          </figure>
          <span className="logo-title is-size-4-desktop is-size-5-mobile m-l-10">
            {WEBSITE_LABEL}
          </span>
        </Link>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
        <a 
          role="button" 
          data-target="navbarMenu" 
          className="navbar-burger" 
          aria-label="menu" 
          aria-expanded="false" 
          onClick={() => setActive(!isActive)}
        >
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
      <div id="navbarMenu" className={menuClassName}>
        <div className="navbar-end">
          <div className="navbar-item">
            <a className="is-size-6-desktop is-size-6-mobile" href={DOCS_ADDRESS}>
              Docs
            </a>
          </div>
          <div className="navbar-item">
            <a className="is-size-6-desktop is-size-6-mobile" href={REPO_ADDRESS}>
              GitHub
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}
