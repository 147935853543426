import React from 'react';
import { Helmet } from 'react-helmet';
import { WEBSITE_LABEL_FULL } from '../data/global';

export default function Head() {
  return (
    <div role="application">
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Fira+Sans:300,400,700&display=swap"
          rel="stylesheet"
        />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{WEBSITE_LABEL_FULL}</title>
        <script defer src="https://use.fontawesome.com/releases/v5.0.7/js/all.js" />
      </Helmet>
    </div>
  );
}
