import React from 'react';
import { DOCS_ADDRESS, REPO_ADDRESS } from '../data/links';
/* eslint-disable jsx-a11y/anchor-is-valid */
// Temporary disable rule for a href links

export default function Footer() {
  return (
    <footer className="footer">
      <div className="p-0-50">
        <p>
          <a href={REPO_ADDRESS}>Git page</a>
        </p>
        <p>
          <a href={DOCS_ADDRESS}>Documentation</a>
        </p>
      </div>
    </footer>
  );
}
