import React from 'react';
import Header from './Header';
import Head from './Head';
import Footer from './Footer';

export type LayoutProps = {
  className?: string;
  children?: React.ReactNode;
};

export default function Layout({ children, className }: LayoutProps) {
  return (
    <div className={className}>
      <Head />
      <Header />
      {children}
      <Footer />
    </div>
  );
}
